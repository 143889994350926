import Head from "next/head"
import React from "react"
import { CartProvider } from "../contexts/useCart"
import { WhatsAppProvider } from "../contexts/WhatsAppProvider"
import { globalStyles } from "../styles/global"
import "../globals.css"
import { ChakraProvider } from "@chakra-ui/react"
import { QueryClientProvider } from "react-query"
import { queryClient } from "../services/queryClient"
import { useRouter } from "next/router"
import Script from "next/script"
import { GTM_ID } from "../services/googleTagManager"
import { GTMPageView } from "../services/googleTagManager"
import links from "/links.json"
import { PopoverProvider } from "../contexts/PopoverContext"
import dynamic from "next/dynamic"
import "../../sentry.client.config.js"

const Footer = dynamic(() => import("../components/Footer").then((mod) => mod.Footer))
const CookiesSessionProvider = dynamic(() =>
  import("../contexts/cookiesSessionProvider").then((mod) => mod.CookiesSessionProvider),
)
const WhatsAppChat = dynamic(() => import("../components/WhatsAppChat").then((mod) => mod.WhatsAppChat))
const WhatsAppButton = dynamic(() =>
  import("../components/WhatsAppChat/WhatsAppButton").then((mod) => mod.WhatsAppButton),
)

globalStyles()

function MyApp({ Component, pageProps }) {
  const path = useRouter().pathname
  const router = useRouter()

  const pathsProducts = {
    categoria: "/categoria",
    segmento: "/segmento",
    "ultra-rapido": "/ultra-rapido",
    search: "/search",
    buscar: "/buscar",
    "[idproduto]": "/[idproduto]",
  }

  React.useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url)
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => router.events.off("routeChangeComplete", handleRouteChange)
  }, [router.events])

  const telasSemWhatsapp = ["/atendimento", "/trabalhe-conosco"]

  return (
    <React.Fragment>
      <Head>
        {/* Outras meta tags para SEO */}
        <meta name="theme-color" content="#58BC03" />
        <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
        <meta name="keywords" content="brindes, produtos promocionais, brindes corporativos, inovação" />
        <meta name="author" content="Innovation Brindes" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="google-site-verification" content="wBWtim4iA3_C8qp-dr_Gyv47Vf0SoEr0b_SpAaPKY" />

        {/* Links para ícones */}
        <link rel="shortcut icon" href="/images/logo-icon.png" />
        <link rel="apple-touch-icon" href="/icon-192x192.png" />
        <link rel="manifest" href="/manifest.json" />
        {/* favicon */}
        <link rel="icon" href="/favicon.png" sizes="any" />
        {/* favicon */}
      </Head>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
      </Script>

      <PopoverProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
            <CartProvider>
              <CookiesSessionProvider>
                <WhatsAppProvider>
                  <Component {...pageProps} />
                  {pathsProducts[Object.keys(pathsProducts).find((key) => path.includes(key))] && (
                    <>
                      <WhatsAppChat />
                      <WhatsAppButton />
                    </>
                  )}
                  {!pathsProducts[Object.keys(pathsProducts).find((key) => path.includes(key))] &&
                    !telasSemWhatsapp.includes(path) && (
                      <>
                        <WhatsAppChat isHomePage />
                        <WhatsAppButton />
                      </>
                    )}

                  <Footer links={links} />
                </WhatsAppProvider>
              </CookiesSessionProvider>
            </CartProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </PopoverProvider>
    </React.Fragment>
  )
}
export default MyApp
