export const GTM_ID = "GTM-PXQHD6F"

export const getDataLayer = (data) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push(data)
  window.dataLayer = dataLayer

  return dataLayer
}

//example of use
// import { getDataLayer } from 'services/googleTagManager'

// getDataLayer({
//   event: 'event',
//   category: 'category',
//   action: 'action',
//   label: 'label',
//   value: 'value',
// })

export const GTMPageView = (url) => {
  const pageEvent = {
    event: "pageview",
    page: url,
  }
  window && window.dataLayer && window.dataLayer.push(pageEvent)
  return pageEvent
}

//example of use
// import { GTMPageView } from 'services/googleTagManager'

// useEffect(() => {
//   GTMPageView('new page title')
// }, [pathname])
