import React, { createContext, useReducer, useContext } from "react"

const PopoverContext = createContext()

const initialState = {
  isOpen: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return { ...state, isOpen: true }
    case "CLOSE":
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export const usePopover = () => {
  const context = useContext(PopoverContext)
  if (!context) {
    throw new Error("usePopover deve ser usado dentro de um PopoverProvider")
  }
  return context
}

// Provider do contexto
export const PopoverProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  //se clicar fora do popover, ele fecha
  const handleClick = (e) => {
    if (e.target.closest("[data-popover]") === null) {
      dispatch({ type: "CLOSE" })
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  //esc key
  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: "CLOSE" })
      }
    }

    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [])

  return <PopoverContext.Provider value={{ state, dispatch }}>{children}</PopoverContext.Provider>
}
